import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22')
];

export const server_loads = [0,2,3];

export const dictionary = {
		"/": [4],
		"/confirm/[businessId]/[tabId]": [5],
		"/confirm/[businessId]/[tabId]/[orderId]": [6],
		"/errors": [7],
		"/kiosk/[businessId]": [8],
		"/locations/[enterpriseId]": [9],
		"/order/[businessId]/dinein/old_tableSessionId": [11,[2,3]],
		"/order/[businessId]/dinein/old_tableSessionId/pay": [12,[2,3]],
		"/order/[businessId]/dinein/[tableNumber]": [10,[2]],
		"/order/[businessId]/pay": [13,[2]],
		"/order/[businessId]/tab/[id]": [14,[2]],
		"/order/[businessId]/takeout": [15,[2]],
		"/payment/[paymentId]": [17],
		"/pay/[businessId]/[paymentId]": [16],
		"/qrcode/enterprise/[enterpriseId]/[id]": [19],
		"/qrcode/[businessId]/[id]": [18],
		"/receipt/[businessId]/complete": [21],
		"/receipt/[businessId]/payment/[paymentId]": [22],
		"/receipt/[businessId]/[orderId]": [20]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';